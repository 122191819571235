import { createStore } from 'vuex'

export default createStore({
  state: {
    loader:0,
    BaseApiURL:"https://ftour.manialab.sa/api/",
    BaseConsultantURL:"https://ftour.manialab.sa/F-tour/public/storage/",
    
    months:{
      en:{
        '01':"JAN",
        '02':"FEB",
        '03':"MAR",
        '04':"APR",
        '05':"MAY",
        '06':"JUN",
        '07':"JUL",
        '08':"AUG",
        '09':"SEP",
        '10':"OCT",
        '11':"NOV",
        '12':"DEC",
      },
      ar:{
        '01':"يناير",
        '02':"فبراير",
        '03':"مارس",
        '04':"أبريل",
        '05':"مايو",
        '06':"يونيو",
        '07':"يوليو",
        '08':"أغسطس",
        '09':"سبتمبر",
        '10':"أكتوبر",
        '11':"نوفمبر",
        '12':"ديسمبر",
      }

    }
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
