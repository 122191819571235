import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueAxios from "vue-axios";
import axios from "axios";
import VueToast from 'vue-toast-notification';
import Select2 from "vue3-select2-component";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueCookies from 'vue3-cookies'

import 'vue-toast-notification/dist/theme-sugar.css';
import "maz-ui/css/main.css";
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";


const app = createApp(App).use(store).use(router);
app.use(i18n);
app.component("Select2", Select2);
app.component('qr-code', VueQRCodeComponent);
app.use(VueAxios, axios);
app.use(VueToast);
app.use(VueCookies);

app.mount("#app");
