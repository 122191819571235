<template>
  <div class=" mainDivold newPattern cw" style="font-size: 0px;" :style="{ backgroundImage: 'url(' + require('@/assets/imgs/patternAA.svg') + ')' }">
    <!-- <img src="./assets//imgs/pattern.svg" class="w100" alt=""> -->
    <div class="pf ov bgb20" v-if="$store.state.loader" style="z-index: 2147483647;">
      <div class="cc">
        <span class="loader"></span>
      </div>
    </div>
    <div class="pf ov ba bgBlue pv10  sh10" style="text-align: left; z-index: 21474836;">
      <div class="switch" style="z-index:99999999999">
        <input id="language-toggle" class="check-toggle check-toggle-round-flat" v-model="lang" type="checkbox">
        <label for="language-toggle"></label>
        <span class="on">AR</span>
        <span class="off">EN</span>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      lang: false,
    }
  },
  
  watch: {
    '$i18n.locale': function(newVal) {
      $( "input[aria-label='Country code']").next().html(this.$t('country_code'));
    },
    "lang":function(new_lang){
      if(new_lang){
        this.changeLocale('en')
      }
      else{
        this.changeLocale('ar')

      }
    }
  },
  methods:{
    
    changeLocale(locale) {
      this.$i18n.locale = locale;
      $("html").attr("lang", "ar");
      if (locale == "ar") {
        $('body').addClass("rtl");
      } else {
        $("html").attr("lang", "en");
        $('body').removeClass("rtl");
      }
    },
   

  },
}
</script>

<style>
 .newPattern{ 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    /* background: transparent linear-gradient(182deg, #13739A 0%, #0F7798 100%) 0% 0% no-repeat padding-box; */
    font-size: 26px;
}
</style>