import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  
  {
    path: '/badge',
    name: 'Badge',
    component: () => import('../views/Badge.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/step1',
    name: 'Step1',
    component: () => import('../views/Step1.vue')
  },
  {
    path: "/",
    component: () => import("../views/Step1.vue"),
    redirect: "/step1",
  },

  {
    path: '/step2',
    name: 'Step2',
    component: () => import('../views/Step2.vue')
  },
  {
    path: '/login',
    name: 'OrganizerLogin',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/check-log',
    name: 'CheckLog',
    component: () => import('../views/CheckLog.vue')
  },
  {
    path: '/consultant-login',
    name: 'ConsultantLogin',
    component: () => import('../views/ConsultantLogin.vue')
  },
  {
    path: '/consultant-dashboard',
    name: 'ConsultantDashboard',
    component: () => import('../views/ConsultantDashboard.vue')
  },
  {
    path: '/reception-login',
    name: 'ReceptionLogin',
    component: () => import('../views/Reception/ReceptionLogin.vue')
  },   
  {
    path: '/reception-dashboard',
    name: 'ReceptionDashboard',
    component: () => import('../views/Reception/ReceptionDashboard.vue')
  },
  {
    path: '/reception-city',
    name: 'ReceptionCity',
    component: () => import('../views/Reception/ReceptionCity.vue')
  }, 
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
