import { createI18n } from "vue-i18n";
const i18n = createI18n({
  locale: "ar",
  messages: {
    en: {
      header: "Welcome to Franchise Tour",
      are_you_registered_before: "Are you registered before?",
      yes: "yes",
      no: "no",
      email: "Email",
      confirm_email: "confirm email",
      confirm_email_is_required: "confirm email is required",
      submit: "submit",
      email_is_required: "Email is required",
      enter_valid_email: "Please enter a valid email",
      account_not_found: "Email not found please register now",
      operation_failed: "Operation Faild please try again",
      failed_to_get_data: "Failed to get data, please try again",
      firstName: "First Name",
      first_name_is_required: "First name is required",
      lastName: "Last Name",
      last_name_is_required: "Last name is required",
      nationality: "Nationality",
      nationality_is_required: "Nationality is required",
      id_number: "Id Number",
      iqama_number: "Iqama Number",
      passport_number: "Passport Number",
      id_number_is_required: "Id number is required",
      iqama_number_is_required: "Iqama number is required",
      passport_number_is_required: "Passport number is required",
      id_25_digit: "Please enter a correct passport number",
      email_dose_not_match: "Emails doesn't match",
      phoneNumber: "Phone Number",
      phoneNumber_is_required: "Phone Number is required",
      country_code: "Country Code",
      gender: "Gender",
      gender_is_required: "Gender is required",
      male: "Male",
      female: "Female",
      Search_a_country: "Search a country",
      Choose_country: "Choose Country",
      Country_of_Residence: "Country of Residence",
      Country_of_Residence_is_required: "Country of residence is required",
      invalid_id: "Please enter a correct ID number",
      correct_iqama: "Please enter a correct Iqama number",
      check_all_fields:
        "Failed to submit form, Please check if all fields fill correclty",
      account_found: "This email already registered",
      please_select_city: "Please select city",
      you_can_select_mutiple_cities: "(you can select mutiple cities)",
      please_select: "Please select",
      city_selected_before: "This city is selected before",
      select_atleast_one_city: "You have to select at least one city",
      captcha_is_required: "Captcha is required",
      captcha_dose_not_match: "Captcha dose not match",
      back: "Back",
      worng_link: "Incorrect link please check the link in your email",
      downlaod: "Download",
      registered_successfully: "Registered successfully",
      next: "Next",
      no_city_ava: "You are already registered in all available cities before",
      invalid_phone: "Please enter a valid phone number",
      from: "From",
      to: "To",
      login: "Login",
      passowrd: "Password",
      otp: "Otp Code",
      passowrd_is_required: "Password is required",
      otp: "Otp Code",
      otp_is_required: "Otp code is required",
      verify: "Verify",
      wrong_otp: "Worng otp",
      email_not_found: "Email not found",
      email_or_pass_wrong: "Email and password doesn't match",
      logout: "Logout",
      camera: "Camera",
      qr_reader: "Qr Reader",
      login_successfully: "Login Successfully",
      registered_cities: "Registered Cities",
      checkIn: "Check In",
      this_visitor_not_registered_not_in_this_city:
        "This visitor not registered in this city",
      cancel: "Cancel",
      checkin_successfully: "Check-in Successfully",
      failed_to_checkin: "Failed to check-in",
      visitor_data: "Visitor Data",
      verfiy: "Verfiy",
      id_pass_iqama_already_used: "Iq/Passport/Iqama number is already used",
      this_number_is_already_used: "This number is already used",
      username_is_required: "Username is required",
      username_not_found: "Username not found",
      username_or_pass_wrong: "Username or password wrong",
      username: "Username",
      registrant: "Registrant",
      date_time: "Date & Time",
      attend: "Attend",
      note_for_visitor: "Note for Visitor",
      note_for_monshaat: "Note for Monshaat",
      check_attend: "Check Attend",
      attended: "Attended",
      send_email: "Send via email",
      close: "Close",
      are_u_sure_close_feedback:
        "Are you sure you want to close this feedback? once close the feedback will not be saved",
      sent_note: "Sent Note",
      save_note: "Save Note",
      note_sent_successfully: "Note saved successfully",
      failed_to_send_note: "Faild to save note",
      feedback_sent_successfully: "Feedback sent successfully to visitor",
      failed_to_send_feedback: "failed to send feedback",
      fill_the_feedback: "The feedback is required",
      fill_the_note: "The note is required",
      failed_to_check_attendence: "Failed to check attendence",
      check_attendence_successfully: "Check attendence successfully",
      feedback_sent: "Feedback sent",
      can_send_feedback: "Can send feedback",
      note_sent: "Note sent",
      can_send_note: "Can send note",
      filter_name: "Filter by visitor name",
      no_result: "no result found",
      enter_text_to_search: "Please enter text to search",
      Booked_Session: "Booked Session",
      Consultants: "Consultants",
      res_Consultants: "Reserve Consultants",
      control_panel: "Control Panel",
      visitor_name: "Visitor Name",
      consultant_name: "Consultant Name",
      session_time: "Session Time",
      session_date: "Dession Date",
      session_cancel_succssfully: "Session cancel succssfully",
      failed_to_cancel_session: "Failed to cancel session",
      search_for_consultant: "Search for consultant",
      search_for_visitor_or_consultant: "Search for visitor or consultant",
      invaled_token: "Please enter correct token",
      book_session: "Book a Session",
      session_booked_scussfully: "Session booked successfully",
      failed_to_booked_session: "Failed to booked session",
      total_session: "Total Session",
      attended_session: "Attended Session",
      scan_the_qr_Code: "Scan the qr Code",
      this_visitor_not_in: "This visitor is not register in this city",
      i_agree:
        "By clicking here, I agree that I have read and understood to the terms and conditions of privacy notice of the General Authority for Small and Medium Enterprises, to view the privacy notice",
      i_agree_2:
        "By clicking here, I agree to the terms of use, to view the term of use",
      press_here: "click here",
      field_is_required: "Field is required",
      resend_otp: "You can resend OTP after",
      resend_successfully: "OPT resend successfully",
      resend: "Resend",
      gate: "Gate",
      workshop: "Workshop",
      location: "Location",
      this_visitor_has_session:"This visitor has another session at the same time",
    },
    ar: {
      header: "مرحباً بك في جولة الامتياز التجاري",
      are_you_registered_before: "هل أنت مسجل مسبقاً؟",
      yes: "نعم",
      no: "لا",
      email: "البريد الإلكتروني",
      confirm_email: "تأكيد البريد الإلكتروني",
      confirm_email_is_required: "الرجاء تأكيد البريد الإلكتروني",
      submit: "إرسال",
      email_is_required: "الرجاء إدخال البريد الإلكتروني",
      enter_valid_email: "الرجاء التأكد من صحة البريد الإلكتروني",
      account_not_found: "البريد الإلكتروني غير متوفر الرجاء التسجيل",
      operation_failed: "لقد فشلت العملية الرجاء إعادة المحاولة",
      failed_to_get_data: "فشل الحصول على البيانات، يرجى المحاولة مرة أخرى",
      firstName: "الاسم الأول",
      first_name_is_required: "الرجاء إدخال الاسم الأول",
      lastName: "الاسم الثاني",
      last_name_is_required: "الرجاء إدخال الاسم الثاني",
      nationality: "الجنسية",
      nationality_is_required: "الرجاء إختيار الجنسية",
      id_number: "رقم الهوية",
      iqama_number: "رقم الإقامة",
      passport_number: "رقم جواز السفر",
      id_number_is_required: "الرجاء إدخال رقم الهوية",
      iqama_number_is_required: "الرجاء إدخال رقم الإقامة",
      passport_number_is_required: "الرجاء إدخال رقم جواز السفر",
      id_25_digit: "الرجاء إدخال رقم جواز السفر الصحيح",
      email_dose_not_match: "البريد الإلكتروني غير متطابق",
      phoneNumber: "رقم الجوال",
      phoneNumber_is_required: "الرجاء إدخال رقم الجوال",
      country_code: "رمز الدولة",
      gender: "الجنس",
      gender_is_required: "الرجاء إختيار الجنس",
      male: "ذكر",
      female: "أنثى",
      Search_a_country: "ابحث عن بلد",
      Choose_country: "اختر بلد",
      Country_of_Residence: "بلد الإقامة",
      Country_of_Residence_is_required: "الرجاء إختيار بلد الإقامة",
      invalid_id: "الرجاء إدخال رقم الهوية الصحيح",
      correct_iqama: "الرجاء ادخال رقم الاقامة الصحيح",
      check_all_fields:
        "فشلت عملية التسجيل، الرجاء التأكد من إدخال جميع الخانات بشكل صحيح",
      account_found: "هذا البريد مسجل مسبقاً",
      please_select_city: "الرجاء اختيار مدينة",
      you_can_select_mutiple_cities: "(بإمكانك اختيار عدة مدن)",
      please_select: "يرجى التحديد",
      city_selected_before: "لقد قمت بإختيار هذه المدينة من قبل",
      select_atleast_one_city: "الرجاء إختيار مدينة واحدة على الأقل",
      captcha_is_required: "هذ الحقل إجباري",
      captcha_dose_not_match: "إن النص المدخل لا يتطابق مع النص أعلاه",
      back: "رجوع",
      worng_link:
        "هناك خطأ في هذا الرابط، الرجاء التأكد من الرابط في بريدك الإلكتروني",
      downlaod: "تحميل",
      registered_successfully: "تم التسجيل بنجاح",
      next: "التالي",
      no_city_ava: "لقد قمت بالتسجيل في جميع المدن المتاحة مسبقاً",
      invalid_phone: "الرجاء إدخال رقم هاتف صحيح",
      from: "من",
      to: "إلى",
      login: "تسجيل دخول",
      passowrd: "كلمة المرور",
      passowrd_is_required: "الرجاء إدخال كلمة المرور",
      otp: "رمز التحقق",
      otp_is_required: "الرجاء إدخال رمز التحقق",
      verify: "تحقق",
      wrong_otp: "رمز التحقق خطأ",
      email_not_found: "البريد الإلكتروني غير مسجل",
      email_or_pass_wrong: "البريد الإلكتروني وكلمة المرور غير متطابقين",
      logout: "تسجيل خروج",
      camera: "Camera",
      qr_reader: "Qr Reader",
      login_successfully: "تم تسجيل الدخول بنجاح",
      registered_cities: "المدن المسجلة",
      checkIn: "تسجيل حضور",
      this_visitor_not_registered_not_in_this_city:
        "هذا الزائر غير مسجل في هذه المدينة",
      cancel: "إلغاء",
      checkin_successfully: "تم تسجيل الحضور بنجاح",
      failed_to_checkin: "فشل تسجيل الحضور",
      visitor_data: "معلومات الزائر",
      verfiy: "تحقق",
      id_pass_iqama_already_used:
        "إن رقم الهوية/جواز السفر/الإقامة مسجل مسبقاً",
      this_number_is_already_used: "هذا الرقم مسجل مسبقاً",
      username_is_required: "الرجاء إدخال اسم المتسخدم",
      username_not_found: "اسم المستخدم غير موجود",
      username_or_pass_wrong: "اسم المستخدم و كلمة المرورة غير متطابقيين",
      username: "اسم المستخدم",
      registrant: "المسجل",
      date_time: "التاريخ & الوقت",
      attend: "الحضور",
      note_for_visitor: "ملاحظات للزائر",
      note_for_monshaat: "ملاحظات لمنشآت",
      check_attend: "تسجيل حضور",
      attended: "تم تسجيل الحضور",
      send_email: "إرسال عبر البريد الإلكتروني",
      close: "إغلاق",
      are_u_sure_close_feedback:
        "هل أنت متأكد من أنك تريد إغلاق هذه الملاحظة؟ لا يتم حفظ هذه الملاحظة",
      sent_note: "الملاحظة المرسلة",
      save_note: "حفظ الملاحظة",
      note_sent_successfully: "تم حفظ الملاحظة بنجاح",
      failed_to_send_note: "فشل حفظ الملاحظة",
      feedback_sent_successfully: "تم إرسال الملاحظة بنجاح",
      failed_to_send_feedback: "فشل إرسال الملاحظة",
      fill_the_feedback: "الرجاء إدخال ملاحظة",
      fill_the_note: "الرجاء إدخال ملاحظة",
      failed_to_check_attendence: "فشل تسجيل الحضور",
      check_attendence_successfully: "تم تسجيل الحضور بنجاح",
      feedback_sent: "تم إرسال الملاحظة",
      can_send_feedback: "بإمكانك إرسال ملاحظة",
      note_sent: "تم إرسال الملاحظة",
      can_send_note: "بإمكانك إرسال ملاحظة",
      filter_name: "بحث من خلال اسم الزائر",
      no_result: "لم يتم العثور على نتائج",
      enter_text_to_search: "الرجاء إدخال نص للبحث",
      Booked_Session: "الجلسات المحجوزة",
      Consultants: "المستشارين",
      res_Consultants: "حجز المستشارين",
      control_panel: "لوحة التحكم",
      visitor_name: "اسم الزائر",
      consultant_name: "اسم المستشار",
      session_time: "وقت الجلسة",
      session_date: "تاريخ الجسلة",
      session_cancel_succssfully: "تم إلغاء الجسلة بنجاح",
      failed_to_cancel_session: "فشل إلغاء الجلسة",
      search_for_consultant: "ابحث عن مستشار",
      search_for_visitor_or_consultant: "ابحث عن زائر أو مستشار",
      invaled_token: "الرجاء إدخال رمز تعريف صحيح",
      book_session: "احجز جلسة",
      session_booked_scussfully: "تم حجز الجلسة بنجاح",
      failed_to_booked_session: "فشل حجز الجلسة الرجاء إعادة المحاولة",
      total_session: "عدد الجلسات",
      attended_session: "عدد الحضور",
      scan_the_qr_Code: "الرجاء مسح رمز QR",
      this_visitor_not_in: "هذا الزئر غير مسجل في هذه المدينة",
      i_agree:
        "أوافق على اشعار الخصوصية الخاص بالهيئة العامة للمنشآت الصغيرة والمتوسطة وللاطلاع",
      i_agree_2: "أوافق على سياسة الاستخدام وللاطلاع",
      press_here: "اضغط هنا",
      field_is_required: "هذا الحقل إجباري",
      resend_otp: "يمكنك إعادة إرسال رمز التحقق بعد",
      resend_successfully: "تم إعادة إرسال رمز التحقق بنجاح",
      resend: "إعادة إرسال",
      gate: "بوابة",
      workshop: "ورشة عمل",
      location: "الموقع",
      this_visitor_has_session:"هذا الزائر لديه جلسة أخرى محجوزة في نفس الوقت",
    },
  },
});
export default i18n;
